


.App {
  padding: 1rem;
  border: 2px solid black;
  width: auto;
  height: auto;
  font-family: Arial;
}

.App * {
  margin: .3rem;
  padding: .5rem;
  max-width: 500px;
  font-family: Arial;
}

.App label, .App Button, .App select{
  font-size: large;
  display: inline-block;
  text-align: right;
}
.App textarea {
  max-height: 300px;
}

.App textarea, .App input{
  font-size: large;
}

.App button{
  background: lightgray;
  color: black;
  border-radius: 10%;
}